import { useState, useEffect } from 'react';

const useUserDetails = (userId) => {
  const [userDetails, setUserDetails] = useState({
    userScore: 0,
    dailyUserScore: 0,
    twitterUsername: '',
    telegramUsername: '',
    solanaWalletAddress: '',
    tokenBalance: 'Loading...',
    solBalance: 'Loading...',
    tier: 'Not eligible',
    creator: false,
    actions: [] // Initialize actions
  });

  useEffect(() => {
    if (userId) {
      fetchUserDetails(userId);
    }
  }, [userId]);

  const fetchUserDetails = async (userId) => {
    console.log(`Fetching user details for userId: ${userId}`);
    try {
      const response = await fetch(`https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/public-userdetails/${userId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched user details:', data);

      const details = {
        userScore: data.score || 0,
        dailyUserScore: data.dailyUserScore || 0,
        twitterUsername: data.twitterUsername || '',
        telegramUsername: data.username || '',
        solanaWalletAddress: data.solanaPublicKey || '',
        tokenBalance: data.tokenBalance || '0.0',
        solBalance: data.solBalance || '0.0',
        tier: 'Not eligible',
        creator: data.creator || false,
        actions: data.actions || [], // Set actions
      };

      // Determine tier based on token balance
      details.tier = determineTier(parseFloat(details.tokenBalance));
      
      // Send tier to the backend if necessary
      if (details.tokenBalance > 0) {
        await updateTierInBackend(userId, details.tier);
      }

      setUserDetails(details);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const determineTier = (balance) => {
    if (balance >= 100000) return 'Tier 1';
    if (balance >= 90000) return 'Tier 2';
    if (balance >= 80000) return 'Tier 3';
    if (balance >= 70000) return 'Tier 4';
    if (balance >= 60000) return 'Tier 5';
    if (balance >= 50000) return 'Tier 6';
    if (balance >= 40000) return 'Tier 7';
    if (balance >= 30000) return 'Tier 8';
    if (balance >= 20000) return 'Tier 9';
    if (balance >= 10000) return 'Tier 10';
    return 'Not eligible';
  };

  const updateTierInBackend = async (userId, tier) => {
    try {
      const response = await fetch('https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/update-tier', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId, tier })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Updated tier in backend:', data);
    } catch (error) {
      console.error('Error updating tier in backend:', error);
    }
  };

  return userDetails;
};

export default useUserDetails;