import React, { useRef, useEffect } from 'react';

const Menu = ({ selectedItem }) => {
  const menuItems = ['Snake', 'Twitter', 'Telegram', 'Buy', 'Screw the dev', 'Profile', 'Referral', 'Validate', 'Creator']; // Add "Creator"
  const menuRef = useRef(null);

  useEffect(() => {
    const selectedElement = menuRef.current.children[selectedItem];
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selectedItem]);

  return (
    <div className="menu" ref={menuRef}>
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`menu-item ${selectedItem === index ? 'selected' : ''}`}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Menu;