import React, { useState, useEffect, useCallback } from 'react';
import './Snake.css';

const CELL_SIZE = 20;
const WIDTH = 10; // Number of cells
const HEIGHT = 10; // Number of cells
const DIRECTIONS = {
  up: { x: 0, y: -1 },
  down: { x: 0, y: 1 },
  left: { x: -1, y: 0 },
  right: { x: 1, y: 0 }
};

const Snake = ({ onBackToMenu }) => {
  const [snake, setSnake] = useState([{ x: 2, y: 2 }]);
  const [food, setFood] = useState({ x: 5, y: 5 });
  const [direction, setDirection] = useState(DIRECTIONS.right);
  const [isPlaying, setIsPlaying] = useState(false);
  const [score, setScore] = useState(0);

  const moveSnake = useCallback(() => {
    setSnake((prevSnake) => {
      const newSnake = [...prevSnake];
      const head = newSnake[0];
      const newHead = {
        x: head.x + direction.x,
        y: head.y + direction.y
      };
      newSnake.unshift(newHead);

      if (newHead.x === food.x && newHead.y === food.y) {
        setFood({
          x: Math.floor(Math.random() * WIDTH),
          y: Math.floor(Math.random() * HEIGHT)
        });
        setScore((prevScore) => prevScore + 1);
      } else {
        newSnake.pop();
      }

      if (
        newHead.x < 0 ||
        newHead.x >= WIDTH ||
        newHead.y < 0 ||
        newHead.y >= HEIGHT ||
        newSnake.slice(1).some(segment => segment.x === newHead.x && segment.y === newHead.y)
      ) {
        setIsPlaying(false);
        setSnake([{ x: 2, y: 2 }]);
        setScore(0);
        return prevSnake;
      }

      return newSnake;
    });
  }, [direction, food]);

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(moveSnake, 200);
      return () => clearInterval(interval);
    }
  }, [isPlaying, moveSnake]);

  const handleKeyPress = (key) => {
    if (!isPlaying && key === 'A') {
      setIsPlaying(true);
      return;
    }
    if (key === 'B') {
      setIsPlaying(false);
      onBackToMenu();
      return;
    }
    if (isPlaying) {
      if (key === 'up' && direction !== DIRECTIONS.down) setDirection(DIRECTIONS.up);
      if (key === 'down' && direction !== DIRECTIONS.up) setDirection(DIRECTIONS.down);
      if (key === 'left' && direction !== DIRECTIONS.right) setDirection(DIRECTIONS.left);
      if (key === 'right' && direction !== DIRECTIONS.left) setDirection(DIRECTIONS.right);
    }
  };

  useEffect(() => {
    const handleSnakeControl = (e) => {
      handleKeyPress(e.detail);
    };

    document.addEventListener('snakeControl', handleSnakeControl);

    return () => {
      document.removeEventListener('snakeControl', handleSnakeControl);
    };
  }, [handleKeyPress]);

  return (
    <div className="snake-game">
      <div className="score">Score: {score}</div>
      {!isPlaying && (
        <div className="start-text">
          <p>Press A to start</p>
          <p>Press B to go back</p>
        </div>
      )}
      <div className="grid" style={{ width: WIDTH * CELL_SIZE, height: HEIGHT * CELL_SIZE }}>
        {snake.map((segment, index) => (
          <div
            key={index}
            className="snake-segment"
            style={{
              left: `${segment.x * CELL_SIZE}px`,
              top: `${segment.y * CELL_SIZE}px`,
              width: CELL_SIZE,
              height: CELL_SIZE
            }}
          ></div>
        ))}
        <div
          className="food"
          style={{
            left: `${food.x * CELL_SIZE}px`,
            top: `${food.y * CELL_SIZE}px`,
            width: CELL_SIZE,
            height: CELL_SIZE
          }}
        ></div>
      </div>
    </div>
  );
};

export default Snake;