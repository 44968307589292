import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Clicker.css';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const Clicker = ({ onBackToMenu, user }) => {
  const [totalScore, setTotalScore] = useState(0);
  const [dailyTotalScore, setDailyTotalScore] = useState(0); // Added state for daily total score
  const [userScore, setUserScore] = useState(0);
  const [dailyUserScore, setDailyUserScore] = useState(0); // Added state for daily user score
  const [sessionUserScore, setSessionUserScore] = useState(0);
  const [client, setClient] = useState(null);
  const clickTimestamps = useRef([]);
  const [isActive, setIsActive] = useState(false); // State for background image
  const accumulatedClicks = useRef(0);

  const handleWebSocketMessage = useCallback((message) => {
    const data = JSON.parse(message.data);
    console.log('WebSocket message received:', data);
    if (data.type === 'initialScores') {
      setTotalScore(data.totalScore);
      console.log('Setting dailyTotalScore:', data.dailyTotalScore); // Log state
      setDailyTotalScore(data.dailyTotalScore);
      if (user && data.userId === user.id) {
        setUserScore(data.userScore);
        setDailyUserScore(data.dailyUserScore);
      }
    } else if (data.type === 'scoreUpdate') {
      setTotalScore(data.score);
      console.log('Updating dailyTotalScore:', data.dailyTotalScore); // Log state
      setDailyTotalScore(data.dailyTotalScore);
      if (user && data.userId === user.id) {
        setUserScore(data.userScore);
        setDailyUserScore(data.dailyUserScore);
      }
    }
  }, [user]);

  useEffect(() => {
    const wsUrl = 'wss://gayboybackendreal-0f7fb05a24f9.herokuapp.com';
    const newClient = new W3CWebSocket(wsUrl);

    newClient.onopen = () => {
      console.log('WebSocket Client Connected');
      if (user) {
        newClient.send(JSON.stringify({ type: 'getInitialScores', userId: user.id }));
      } else {
        newClient.send(JSON.stringify({ type: 'getInitialScores' }));
      }
    };

    newClient.onmessage = handleWebSocketMessage;

    newClient.onclose = () => {
      console.log('WebSocket Client Disconnected');
    };

    setClient(newClient);

    return () => {
      newClient.close();
    };
  }, [handleWebSocketMessage, user]);

  const handleKeyPress = (key) => {
    if (key === 'A') {
      const now = Date.now();
      clickTimestamps.current.push(now);

      clickTimestamps.current = clickTimestamps.current.filter(
        timestamp => now - timestamp <= 100
      );

      if (clickTimestamps.current.length <= 2) {
        // Trigger background change
        setIsActive(true);
        setTimeout(() => setIsActive(false), 200); // Revert after 0.2 seconds

        // Locally update the scores
        if (user) {
          setUserScore(prevScore => prevScore + 1);
          setDailyUserScore(prevScore => prevScore + 1);
        } else {
          setSessionUserScore(prevScore => prevScore + 1);
        }
        setTotalScore(prevScore => prevScore + 1);
        setDailyTotalScore(prevScore => prevScore + 1);

        // Accumulate clicks to send to the server later
        accumulatedClicks.current += 1;
      } else {
        console.log('Too many clicks in a short period of time - possible auto clicker detected');
      }
    }
    if (key === 'B') {
      onBackToMenu();
    }
  };

  useEffect(() => {
    const handleClickerControl = (e) => {
      handleKeyPress(e.detail);
    };

    document.addEventListener('clickerControl', handleClickerControl);

    return () => {
      document.removeEventListener('clickerControl', handleClickerControl);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (accumulatedClicks.current > 0 && client && client.readyState === client.OPEN) {
        const clicksToSend = accumulatedClicks.current;
        accumulatedClicks.current = 0;
        client.send(JSON.stringify({ type: 'increment', userId: user ? user.id : 'guest', clicks: clicksToSend }));
      }
    }, 10000); // Send updates every 10 seconds

    return () => clearInterval(interval);
  }, [client, user]);

  return (
    <div className={`clicker-game ${isActive ? 'active' : 'initial'}`}>
      <div className="score-container">
        <div className="daily-total-score">Burned today: <p>{dailyTotalScore}</p></div>
        <div className="daily-user-score">By you: <p>{user ? dailyUserScore : sessionUserScore}</p></div>
      </div>
      <div className="score-container2">
        <div className="total-score">Total Burned: <p>{totalScore}</p></div>
        <div className="user-score">By you: <p>{user ? userScore : sessionUserScore}</p></div>
      </div>
    </div>
  );
};

export default Clicker;