import React, { useEffect, useState, useRef } from 'react';
import useUserDetails from './useUserDetails'; // Import the custom hook
import TwitterConnectButton from './TwitterConnectButton'; // Import the TwitterConnectButton

const Profile = ({ user, onBackToMenu }) => {
  const userId = user?.id || null;
  const { userScore, dailyUserScore, twitterUsername, telegramUsername, solanaWalletAddress, tokenBalance, solBalance, tier } = useUserDetails(userId);
  const [startPressed, setStartPressed] = useState(false); // State to track if "START" is pressed
  const twitterConnectRef = useRef();

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.detail === 'B') {
        onBackToMenu();
      } else if (e.detail === 'start') {
        setStartPressed(true); // Update state when "START" is pressed
      }
    };

    document.addEventListener('profileControl', handleKeyPress);

    return () => {
      document.removeEventListener('profileControl', handleKeyPress);
    };
  }, [onBackToMenu]);

  useEffect(() => {
    if (startPressed && !twitterUsername && twitterConnectRef.current) {
      // If START is pressed and Twitter is not linked, trigger TwitterConnectButton
      twitterConnectRef.current.connect();
    }
  }, [startPressed, twitterUsername]);


  if (!userId) {
    return (
      <div className="profile-page">
        <p>No user data available. Please use your MEMEBOY through telegram.</p>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <h1>Your Profile</h1>
      <p className="normal-text">Username: {telegramUsername}</p>
      <p className="normal-text">ID: {user?.id}</p>
      <p className="normal-text">Tier: {tier}</p>
      <p className="normal-text">Your Score: {userScore}</p>
      <p className="normal-text">Twitter: @{twitterUsername || 'Press "START" to link'}</p>
      {!twitterUsername && (
        <TwitterConnectButton userId={userId} ref={twitterConnectRef} />
      )}
      <p className="normal-text1">Wallet Address:</p> <p className="small-text">{solanaWalletAddress}</p>
      <p className="normal-text">$MEMEBOY Balance: {tokenBalance}</p>
    </div>
  );
};

export default Profile;