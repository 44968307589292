import React, { useEffect, useState } from 'react';
import useUserDetails from './useUserDetails';
import axios from 'axios';

const Creator = ({ user, onBackToMenu }) => {
  const userId = user?.id || null;
  const { twitterUsername, tier, creator } = useUserDetails(userId);
  const [selectPressed, setSelectPressed] = useState(false); // State to track if "SELECT" is pressed
  const [startPressed, setStartPressed] = useState(false); 

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.detail === 'B') {
        if (selectPressed || startPressed) {
          setSelectPressed(false);
          setStartPressed(false);
        } else {
          onBackToMenu();
        }
      } else if (e.detail === 'select') {
        setSelectPressed(true);
        setStartPressed(false); // Ensure startPressed is reset when selectPressed is true
      } else if (e.detail === 'start') {
        setStartPressed(true);
        setSelectPressed(false); // Ensure selectPressed is reset when startPressed is true
      } else if (e.detail === 'A') {
        if (startPressed) {
          becomeCreator();
        }
      }
    };
  
    document.addEventListener('creatorControl', handleKeyPress);
  
    return () => {
      document.removeEventListener('creatorControl', handleKeyPress);
    };
  }, [selectPressed, startPressed, onBackToMenu]);

  const becomeCreator = async () => {
    if (tier === 'Not eligible(asd)' || parseInt(tier.split(' ')[1]) < 1 || parseInt(tier.split(' ')[1]) > 10) {
      alert('You do not meet the tier requirements to become a creator.');
      return;
    }

    try {
      await axios.post('https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/become-creator', { userId });
      alert('You are now a creator!');
      window.location.reload();
    } catch (error) {
      console.error('Error becoming creator:', error);
    }
  };
  

  if (!userId) {
    return (
      <div className="creator">
        <p>No user data available. Please use your MEMEBOY through telegram.</p>
      </div>
    );
  }

  return (
    <div className="creator">
      {selectPressed ? (
        <div>
          <h1>Information on Posting</h1>
          <p>As a creator, you can post tweets about MEMEBOY to earn points.</p>
          <p>Points are awarded based on engagement metrics such as likes, retweets, and comments.</p>
          <p>Make sure your tweets contain one of the following key words: #MEMEBOY or @MemeBoySol</p>
        </div>
      ) : startPressed ? (
        <div>
          <h1>Become a creator</h1>
          <p>Publish tweets about MEMEBOY. Validators will engage with your tweets and you earn points!</p>
          {creator ? (
            <p>You are already a creator!</p>
          ) : (
            <div>
              <p>Press "A" to become a creator</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1>Creator Dashboard</h1>
          <p>Make posts about MEMEBOY and earn points!</p>
          <p>Twitter: @{twitterUsername || 'Not Linked - Link your twitter in your profile'}</p>
          <p className="normal-text">Tier: {tier}</p>
          <p>{creator ? 'You are a creator!' : 'Press "START" to become a creator'}</p>
          <p>Press "SELECT" to read more info about posting</p>
        </div>
      )}
    </div>
  );
};

export default Creator;