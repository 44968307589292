import React, { useEffect, useState, useRef } from 'react';
import useUserDetails from './useUserDetails';
import TwitterConnectButton from './TwitterConnectButton';
import axios from 'axios';

const Farming = ({ user, onBackToMenu }) => {
  const userId = user?.id || null;
  const { userScore, dailyUserScore, twitterUsername, telegramUsername, solanaWalletAddress, tokenBalance, solBalance, tier, actions } = useUserDetails(userId);
  const [tweets, setTweets] = useState([]);
  const [creatorTweets, setCreatorTweets] = useState([]);
  const [loadingTweets, setLoadingTweets] = useState(true);
  const [loadingCreatorTweets, setLoadingCreatorTweets] = useState(true);
  const [startPressed, setStartPressed] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0); // Focused index state
  const [sessionActions, setSessionActions] = useState([]); // Local state for session actions
  const twitterConnectRef = useRef();
  const containerRef = useRef(); // Ref for the container element

  const elements = useRef([]); // Ref to store elements for focus

  const getMultiplier = (tier) => {
    switch (tier) {
      case 'Tier 1': return 2.0;
      case 'Tier 2': return 1.9;
      case 'Tier 3': return 1.8;
      case 'Tier 4': return 1.7;
      case 'Tier 5': return 1.6;
      case 'Tier 6': return 1.5;
      case 'Tier 7': return 1.4;
      case 'Tier 8': return 1.3;
      case 'Tier 9': return 1.2;
      case 'Tier 10': return 1.1;
      default: return 1.0;
    }
  };

  useEffect(() => {
    const fetchTweets = async () => {
      try {
        setLoadingTweets(true);
        const response = await axios.get('https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/api/company-tweets');
        console.log('Fetched tweets:', response.data);
        setTweets(response.data || []);
      } catch (error) {
        console.error('Error fetching tweets:', error);
      } finally {
        setLoadingTweets(false);
      }
    };
    fetchTweets();
  }, []);

  useEffect(() => {
    const fetchCreatorTweets = async () => {
      try {
        setLoadingCreatorTweets(true);
        const response = await axios.get('https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/api/creator-tweets');
        console.log('Fetched creator tweets:', response.data);
        setCreatorTweets(response.data || []);
      } catch (error) {
        console.error('Error fetching creator tweets:', error);
      } finally {
        setLoadingCreatorTweets(false);
      }
    };
    fetchCreatorTweets();
  }, []);

  const handleInteraction = async (tweetId, action) => {
    if (tier === 'Not eligible') {
      alert('In order to validate tweets you need to connect your Twitter into your profile and deposit some $MEMEBOY to your MemeBoy Wallet.');
      return;
    }

    try {
      const endpoint = {
        like: 'https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/like-tweet',
        retweet: 'https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/retweet-tweet',
        comment: 'https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/comment-tweet',
      }[action];

      if (action === 'comment') {
        const comment = prompt('Enter your comment:');
        if (!comment) return;

        await axios.post(endpoint, { tweetId, userId, comment });
      } else {
        await axios.post(endpoint, { tweetId, userId });
      }

      // Update session actions state
      setSessionActions(prevActions => [...prevActions, { tweetId, action }]);

      alert(`${action.charAt(0).toUpperCase() + action.slice(1)} successful!`);
    } catch (error) {
      console.error(`Error ${action}ing tweet:`, error);
    }
  };

  const hasUserInteracted = (tweetId, actionType) => {
    return (
      actions.some(action => action.tweetId === tweetId && action.action === actionType) ||
      sessionActions.some(action => action.tweetId === tweetId && action.action === actionType)
    );
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.detail === 'B') {
        onBackToMenu();
      } else if (e.detail === 'start') {
        setStartPressed(true);
      } else if (e.detail === 'up') {
        setFocusedIndex((prev) => Math.max(prev - 1, 0));
      } else if (e.detail === 'down') {
        setFocusedIndex((prev) => Math.min(prev + 1, elements.current.length - 1));
      }
    };

    document.addEventListener('farmingControl', handleKeyPress);

    return () => {
      document.removeEventListener('farmingControl', handleKeyPress);
    };
  }, [onBackToMenu]);

  useEffect(() => {
    if (startPressed && !twitterUsername && twitterConnectRef.current) {
      twitterConnectRef.current.connect();
    }
  }, [startPressed, twitterUsername]);

  useEffect(() => {
    const focusedElement = elements.current[focusedIndex];
    if (focusedElement) {
      focusedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      focusedElement.focus();
    }
  }, [focusedIndex]);

  if (!userId) {
    return (
      <div className="profile-page">
        <p>No user data available. Please use your MEMEBOY through telegram.</p>
      </div>
    );
  }

  return (
    <div className="farming" ref={containerRef}>
      <div
        className={`example-element ${focusedIndex === 0 ? 'focused' : ''}`}
        ref={(el) => (elements.current[0] = el)}
        tabIndex="0"
      >
        <h1>Validate Tweets</h1>
        <p>Earn points by validating tweets on your meme boy device!</p>
        <p>Username: {telegramUsername}</p>
        <p>Tier: {tier}</p>
        <p>Multiplier: {getMultiplier(tier)}x</p>
        <p>Twitter: @{twitterUsername || 'Press "START" to link'}</p>
        {!twitterUsername && (
          <TwitterConnectButton userId={userId} ref={twitterConnectRef} />
        )}
      </div>
      {tier !== 'Not eligible' ? (
        <div>
          <div className="company">
            <h1>MEMEBOY Tweets</h1>
            {loadingTweets ? (
              <p>Loading tweets...</p>
            ) : tweets.length === 0 ? (
              <p>No tweets available.</p>
            ) : (
              tweets.map((tweet, index) => (
                <div
                  key={tweet.tweetId} // Use tweetId instead of id
                  className={`company-tweets ${focusedIndex === index + 1 ? 'focused' : ''}`}
                  ref={(el) => (elements.current[index + 1] = el)}
                  tabIndex="0"
                >
                  <div className="twitter-inside-container">
                    <h3>@MemeBoySol</h3>
                    <p>{tweet.text}</p>
                    {tweet.media && tweet.media.length > 0 && (
                      <div className="tweet-media">
                        {tweet.media.map((mediaUrl, mediaIndex) => (
                          <img key={mediaIndex} src={mediaUrl} alt="Tweet Media" />
                        ))}
                      </div>
                    )}
                    <div className="twitter-button-container">
                      <button
                        className={`twitter-button ${hasUserInteracted(tweet.tweetId, 'like') ? 'interacted' : ''}`} // Use tweetId instead of id
                        onClick={() => handleInteraction(tweet.tweetId, 'like')} // Use tweetId instead of id
                      >
                        ❤️
                      </button>
                      <button
                        className={`twitter-button ${hasUserInteracted(tweet.tweetId, 'retweet') ? 'interacted' : ''}`} // Use tweetId instead of id
                        onClick={() => handleInteraction(tweet.tweetId, 'retweet')} // Use tweetId instead of id
                      >
                        🔁
                      </button>
                      <button
                        className={`twitter-button ${hasUserInteracted(tweet.tweetId, 'comment') ? 'interacted' : ''}`} // Use tweetId instead of id
                        onClick={() => handleInteraction(tweet.tweetId, 'comment')} // Use tweetId instead of id
                      >
                        💬
                      </button>
                    </div>
                    <a href={`https://x.com/memeboysol/status/${tweet.tweetId}`} target="_blank" rel="noopener noreferrer">Check the tweet</a> {/* Use tweetId instead of id */}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="validator">
            <h1>Tweets from creators</h1>
            {loadingCreatorTweets ? (
              <p>Loading tweets from creators...</p>
            ) : creatorTweets.length === 0 ? (
              <p>No tweets found</p>
            ) : (
              creatorTweets.map((tweet, index) => ( // Adjust this line to handle individual tweets
                <div
                  key={tweet.tweetId} // Use tweetId instead of id
                  className={`creator-tweets ${focusedIndex === tweets.length + index + 1 ? 'focused' : ''}`}
                  ref={(el) => (elements.current[tweets.length + index + 1] = el)}
                  tabIndex="0"
                >
                  <div className="twitter-inside-container">
                    <h3>@{tweet.username}</h3> {/* Display tweet username */}
                    <p>{tweet.text}</p>
                    {tweet.media && tweet.media.length > 0 && (
                      <div className="tweet-media">
                        {tweet.media.map((mediaUrl, mediaIndex) => (
                          <img key={mediaIndex} src={mediaUrl} alt="Tweet Media" />
                        ))}
                      </div>
                    )}
                    <div className="twitter-button-container">
                      <button
                        className={`twitter-button ${hasUserInteracted(tweet.tweetId, 'like') ? 'interacted' : ''}`} // Use tweetId instead of id
                        onClick={() => handleInteraction(tweet.tweetId, 'like')} // Use tweetId instead of id
                      >
                        ❤️
                      </button>
                      <button
                        className={`twitter-button ${hasUserInteracted(tweet.tweetId, 'retweet') ? 'interacted' : ''}`} // Use tweetId instead of id
                        onClick={() => handleInteraction(tweet.tweetId, 'retweet')} // Use tweetId instead of id
                      >
                        🔁
                      </button>
                      <button
                        className={`twitter-button ${hasUserInteracted(tweet.tweetId, 'comment') ? 'interacted' : ''}`} // Use tweetId instead of id
                        onClick={() => handleInteraction(tweet.tweetId, 'comment')} // Use tweetId instead of id
                      >
                        💬
                      </button>
                    </div>
                    <a href={`https://x.com/${tweet.username}/status/${tweet.tweetId}`} target="_blank" rel="noopener noreferrer">Check the tweet</a> {/* Use tweetId instead of id */}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      ) : (
        <p>In order to validate tweets you need to connect your twitter into your profile and deposit some $MEMEBOY to your MemeBoy Wallet.</p>
      )}
    </div>
  );
};

export default Farming;