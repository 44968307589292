import React, { forwardRef, useImperativeHandle } from 'react';

const TwitterConnectButton = forwardRef(({ userId }, ref) => {
  const handleTwitterConnect = () => {
    const twitterAuthUrl = `https://gayboybackendreal-0f7fb05a24f9.herokuapp.com/auth/twitter?userId=${userId}`;
    const twitterAppUrl = `twitter://user?screen_name=your_twitter_handle`;

    // Detect platform
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Create a hidden iframe to open the app URL
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = twitterAppUrl;

      // Append iframe to the body
      document.body.appendChild(iframe);

      // Use a timeout to detect if the app URL scheme fails
      setTimeout(() => {
        document.body.removeChild(iframe);
        // Check if the user stayed on the current page
        window.location.href = twitterAuthUrl;
      }, 1000);
    } else {
      // Open in a new tab on desktop
      window.open(twitterAuthUrl, '_blank');
    }
  };

  useImperativeHandle(ref, () => ({
    connect: handleTwitterConnect
  }));

  return null; // No visible button
});

export default TwitterConnectButton;