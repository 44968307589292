import React, { useState, useEffect } from 'react';
import './App.css';
import Menu from './Menu';
import Snake from './Snake';
import Clicker from './Clicker';
import Referral from './Referral';
import Profile from './Profile';
import Farming from './Farming';
import Dpad from './dpad.svg';
import Creator from './Creator';


const App = () => {
  const [screen, setScreen] = useState('menu');
  const [selectedItem, setSelectedItem] = useState(0);
  const [isTelegram, setIsTelegram] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let initDataUnsafe = null;
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      initDataUnsafe = tg.initDataUnsafe;
      console.log('Telegram initDataUnsafe:', initDataUnsafe); // Debugging
      if (initDataUnsafe && initDataUnsafe.user) {
        setIsTelegram(true);
        setUser(initDataUnsafe.user);
        setShowPopup(false); // Ensure popup is not shown if connected through Telegram

        // Update the username on the server
        updateUsername(initDataUnsafe.user);
      } else {
        setShowPopup(true);
      }
    } else {
      setShowPopup(true);
    }
  }, []);

  const updateUsername = async (user) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://gayboybackendreal-0f7fb05a24f9.herokuapp.com';
    try {
      const response = await fetch(`${baseUrl}/update-username`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.id, username: user.username })
      });
      if (response.ok) {
        console.log(`Username updated: ${user.username}`);
      } else {
        console.error('Failed to update username');
      }
    } catch (error) {
      console.error('Error updating username:', error);
    }
  };

  const handleButtonPress = (button) => {
    if (screen === 'menu') {
      if (button === 'A') {
        if (selectedItem === 0) {
          setScreen('snake');
        } else if (selectedItem === 1) {
          window.open('https://x.com/MemeBoySol', '_blank');
        } else if (selectedItem === 2) {
          window.open('https://t.me/memeboysol', '_blank');
        } else if (selectedItem === 3) {
          window.open('https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in&inputMint=sol&outputMint=31iR1yXYp7uuRhMJsM1HqacngiyxKbxjpwaAtzyremyq', '_blank');
        } else if (selectedItem === 4) {
          setScreen('clicker');
        } else if (selectedItem === 5) {
          setScreen('profile');
        } else if (selectedItem === 6) {
          setScreen('referral');
        } else if (selectedItem === 7) {
          setScreen('farming');
        } else if (selectedItem === 8) { // New case for Creator
          setScreen('creator');
        }
      } else if (button === 'up') {
        setSelectedItem((prev) => (prev > 0 ? prev - 1 : prev));
      } else if (button === 'down') {
        setSelectedItem((prev) => (prev < 8 ? prev + 1 : prev)); // Adjust to the number of items
      }
    } else {
      const event = new CustomEvent(`${screen}Control`, { detail: button });
      document.dispatchEvent(event);
    }
  };

  return (
    <div className="gameboy">
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>You are not connected via Telegram</p>
            <button onClick={() => window.open('https://t.me/MEMEBOYGAMEBOT?start=website', '_blank')}>Open in Telegram</button>
            <button onClick={() => setShowPopup(false)}>Continue without saving my progress</button>
          </div>
        </div>
      )}
      <div className="bg-line"></div>
      <div className="bg-line2"></div>
      <div className="bg-line3"></div>
      <div className="bg-voicepanel">
        <div className="bg-voice-line"></div>
        <div className="bg-voice-line"></div>
        <div className="bg-voice-line"></div>
        <div className="bg-voice-line"></div>
        <div className="bg-voice-line"></div>
        <div className="bg-voice-line"></div>
      </div>
      <div className="screen-border">
        <div className="power"></div>
        <div className="screen">
  {screen === 'menu' && <Menu selectedItem={selectedItem} />}
  {screen === 'snake' && <Snake onBackToMenu={() => setScreen('menu')} />}
  {screen === 'clicker' && <Clicker onBackToMenu={() => setScreen('menu')} user={user} />}
  {screen === 'profile' && <Profile onBackToMenu={() => setScreen('menu')} user={user} />}
  {screen === 'referral' && <Referral onBackToMenu={() => setScreen('menu')} user={user} />}
  {screen === 'farming' && <Farming onBackToMenu={() => setScreen('menu')} user={user} />}
  {screen === 'creator' && <Creator onBackToMenu={() => setScreen('menu')} user={user} />}
</div>
      </div>
      <div className='logo'><h1>MEMEBOY</h1></div>
      <div className="controls">
        <div className="dpad">
          <div onClick={() => handleButtonPress('up')} className="dpad-up"></div>
          <div onClick={() => handleButtonPress('left')} className="dpad-left"></div>
          <div onClick={() => handleButtonPress('right')} className="dpad-right"></div>
          <div onClick={() => handleButtonPress('down')} className="dpad-down"></div>
        </div>
        <div className="buttons">
        <div onClick={() => handleButtonPress('B')} className="button-b">B</div>
        <div onClick={() => handleButtonPress('A')} className="button-a">A</div>
        </div>
      </div>
      <div className="pause-buttons">
        <div className="select-button-cont">
          <div onClick={() => handleButtonPress('select')} className="select-button"></div>
          <p>SELECT</p>
        </div>
        <div className="start-button-cont">
        <div onClick={() => handleButtonPress('start')} className="start-button"></div>
        <p>START</p>
        </div>
      </div>
    </div>
  );
};

export default App;