import React, { useState, useEffect } from 'react';
import './Referral.css';

const Referral = ({ onBackToMenu, user }) => {
  const userId = user?.id || null;
  const [referralLink, setReferralLink] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [logs, setLogs] = useState([]); // State to store logs

  useEffect(() => {
    const fetchReferralData = async (userId) => {
      const baseUrl = 'https://gayboybackendreal-0f7fb05a24f9.herokuapp.com'; // Hardcoded URL
      const url = `${baseUrl}/referral/${userId}`;
      setLogs(prevLogs => [...prevLogs, `Fetching from URL: ${url}`]);
      try {
        const response = await fetch(url);
        const data = await response.json();
        setLogs(prevLogs => [...prevLogs, `Fetched referral data: ${JSON.stringify(data)}`]);
        return data;
      } catch (error) {
        setLogs(prevLogs => [...prevLogs, `Error fetching referral data: ${error.message}`]);
        return { referralLink: '', referralCount: 0 };
      }
    };

    if (user) {
      setLogs(prevLogs => [...prevLogs, `Fetching referral data for user: ${user.id}`]);
      fetchReferralData(user.id).then((data) => {
        setReferralLink(data.referralLink);
        setReferralCount(data.referralCount);
      });
    }
  }, [user]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.detail === 'B') {
        onBackToMenu();
      } else if (e.detail === 'select') {
        copyToClipboard(); 
      }
    };

    document.addEventListener('referralControl', handleKeyPress);

    return () => {
      document.removeEventListener('referralControl', handleKeyPress);
    };
  }, [onBackToMenu]);


  const copyToClipboard = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink)
        .then(() => {
          setLogs(prevLogs => [...prevLogs, `Referral link copied to clipboard: ${referralLink}`]);
          alert('Referral link copied to clipboard!');
        })
        .catch(err => {
          setLogs(prevLogs => [...prevLogs, `Failed to copy referral link: ${err.message}`]);
          alert('Failed to copy referral link');
        });
    }
  };

  if (!userId) {
    return (
      <div className="profile-page">
        <p>No user data available. Please use your MEMEBOY through telegram.</p>
      </div>
    );
  }

  return (
    <div className="referral">
      <h1>Referral Program</h1>
      <p>Refer your friends and earn 1000 points for each who joins</p>
      <p>Referral link:</p>
      <p
        className="referral-link"
        onClick={copyToClipboard}
        style={{ cursor: 'pointer'}}
      >
        {referralLink}
      </p>
      <p>{referralCount} people have joined from your link!</p>
    </div>
  );
};

export default Referral;